<template>
  <HeaderApp />
  <div
    :class="
      '  mx-2 mt-14 p-3 bg-slate-100 dark:bg-bgFullDark rounded-2xl ' +
      ($store.getters['isRtl'] ? ' md:mr-60 ' : ' md:ml-60  ')
    "
  >
    <div class="flex mb-4">
      <p class="font-semibold text-xl text-textBlack dark:text-textWhite mx-3">
        {{ $t("faq") }}
      </p>
    </div>

    <div
      class="w-11/12 mx-auto md:mx-0 bg-white dark:bg-bgLightDark p-3 m-2 rounded-2xl"
    >
      <div
        id="accordion-color"
        data-accordion="collapse"
        data-active-classes="border-primaryColor text-blue-600"
        v-for="(faq, index) in faqs"
        :key="index"
      >
        <h2 id="accordion-color-heading-1">
          <button
            type="button"
            class="flex dark:bg-bgLightDark items-center justify-between w-full px-1 py-4 my-3 font-medium text-right text-textBlack1 dark:text-textWhite1 border-l-0 border-r-0 border-t-0 border border-gray-300 dark:border-gray-600 hover:border-primaryColor"
            @click="openFaq(faq)"
          >
            <span
              ><b>{{ faq.question }}</b></span
            >
            <svg
              data-accordion-icon
              class="w-3 h-3 rotate-180 shrink-0"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 10 6"
            >
              <path
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M9 5 5 1 1 5"
              />
            </svg>
          </button>
        </h2>

        <!-- extra sale information-->
        <div id="accordion-color-body-1 mb-16" class="px-1" v-if="faq.isActive">
          <p class="text-textBlack1 dark:text-textWhite1 text-md">
            {{ faq.answer }}
          </p>
          <button
            class="text-white text-sm md:text-base bg-primaryColor px-6 md:px-4 py-1 my-3 rounded-2xl items-center hover:shadow-md"
            v-if="faq.tutorial != ''"
          >
            <i class="fa-brands fa-youtube px-1"></i> {{ $t("tutorial") }}
          </button>
        </div>
      </div>
    </div>
  </div>

  <confirm-dialogue ref="confirmDialogue"></confirm-dialogue>
  <ResultDialog ref="ResultDialog"></ResultDialog>
</template>
<script>
//import hearder-app
import HeaderApp from "../../components/Navbar/header-app.vue";

import ConfirmDialogue from "../../components/Modals/ConfirmDialogue.vue";
import ResultDialog from "../../components/Modals/ResultDialog.vue";

export default {
  name: "CHANGE_PASSWRD",
  components: {
    HeaderApp,

    ConfirmDialogue,
    ResultDialog,
  },
  data() {
    return {
      isSubmited: false,
      isSubmitedEdit: false,
      current: {},
      faqs: [
        {
          id: 1,
          question: this.$t("faq_1_title"),
          answer: this.$t("faq_1_description"),
          tutorial: "https://www.youtube.com/watch?v=1q8XZgZFa1s",
          isActive: false,
        },
        {
          id: 2,
          question: this.$t("faq_2_title"),
          answer: this.$t("faq_2_description"),
          tutorial: "https://www.youtube.com/watch?v=1q8XZgZFa1s",
          isActive: false,
        },
        {
          id: 3,
          question: this.$t("faq_3_title"),
          answer: this.$t("faq_3_description"),
          tutorial: "",
          isActive: false,
        },
        {
          id: 4,
          question: this.$t("faq_4_title"),
          answer: this.$t("faq_4_description"),
          tutorial: "",
          isActive: false,
        },
        {
          id: 5,
          question: this.$t("faq_5_title"),
          answer: this.$t("faq_5_description"),
          tutorial: "https://www.youtube.com/watch?v=1q8XZgZFa1s",
          isActive: false,
        },
        {
          id: 6,
          question: this.$t("faq_6_title"),
          answer: this.$t("faq_6_description"),
          tutorial: "",
          isActive: false,
        },
        {
          id: 7,
          question: this.$t("faq_7_title"),
          answer: this.$t("faq_7_description"),
          tutorial: "",
          isActive: false,
        },
      ],
    };
  },
  created() {
    this.getSettings();
  },
  watch: {
    // eslint-disable-next-line no-unused-vars
    getResultDialog(newValue, oldValue) {
      if (newValue) {
        this.$refs.ResultDialog.show({
          message: this.$store.state.settings.resultMsg,
          okButton: this.$t("done"),
          isSuccess: this.$store.state.settings.isSuccess,
        });

        // Reset the deleteSuccess state after showing the dialog
        this.$store.commit("settings/SET_DELETE_SUCCESS", false);
      }
    },
  },

  computed: {
    getResultDialog() {
      return this.$store.state.settings.isShowResultDialog;
    },
    currentSetting() {
      return this.$store.state.settings.settings;
    },

    isDark() {
      return this.$store.state.theme == "dark";
    },
    isRtl() {
      return (
        localStorage.getItem("lang_wise_manager") == "ar" ||
        localStorage.getItem("lang_wise_manager") == "ku" ||
        //if null
        localStorage.getItem("lang_wise_manager") == null ||
        //if empty
        localStorage.getItem("lang_wise_manager") == ""
      );
    },
    isBtnLoading() {
      return this.$store.state.settings.isBtnLoading;
    },
  },

  methods: {
    async getSettings() {
      await this.$store.dispatch("settings/getSettings", {});

      this.set_expire_day_reminder =
        this.currentSetting.set_expire_day_reminder;
      this.set_item_less = this.currentSetting.set_item_less;
    },
    openFaq(item) {
      item.isActive = !item.isActive;
      //loop through faqs when item.id == faq.id set isActive = true
    },
  },
};
</script>
